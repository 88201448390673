import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import store from 'store';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/cookies.module.scss';
import { useMPEvent } from '@util/mixpanel';
import locale from '@util/locale';

export default function Cookies({ data }) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    copy: { copy },
    toggleLeft,
    toggleRight,
    personalizationHeader,
    analyticsHeader,
    personalizationSubTitle,
    analyticsSubTitle,
    personalizationCopy: { personalizationCopy },
    analyticsCopy: { analyticsCopy },
  } = data.contentfulCookiesPage;

  const personalizationStored = store.get('personalization');
  const analyticsStored = store.get('analytics');
  const [personalization, setPersonalization] = useState(personalizationStored);
  const [analytics, setAnalytics] = useState(analyticsStored);
  const { trackPageLoad } = useMPEvent();

  function handleChange(event) {
    const { name, checked } = event.target;
    store.set(name, checked);
    if (name === 'personalization') setPersonalization(checked);
    else if (name === 'analytics') setAnalytics(checked);
  }

  useEffect(() => {
    // hides the landing page Cookies popup
    // on mount of this page, we assume cookies prefs have been captured
    store.set('cookies', true);
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/cookies`,
      url_name: 'cookies',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout node_locale={nodeLocale} pageTitle={pageTitle} page="cookies">
      <PageHero data={{ header }} />

      <Container className="my-4">
        <Row>
          <Col xs={12}>
            <p>{copy}</p>
          </Col>
        </Row>
        <Row>
          <Form onChange={handleChange}>
            <div className={styles.cookieCard}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <strong>{locale[nodeLocale].necessaryCookiesHeader}</strong>
                    <div className={styles.checkWrapper}>
                      <Form.Label
                        className={`${styles.toggleFormLabel}`}
                        htmlFor="necessary-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleLeft}</p>
                      </Form.Label>
                      <Form.Check
                        name="necessaryCookies"
                        type="switch"
                        id="necessary-toggle"
                        checked={true}
                        disabled
                      />
                      <Form.Label
                        className={styles.toggleFormLabel}
                        htmlFor="necessary-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleRight}</p>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {locale[nodeLocale].necessaryCookiesBody}
                  </p>
                </Col>
              </Row>
            </div>
            <div className={styles.cookieCard}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <strong>{personalizationHeader}</strong>
                    <div className={styles.checkWrapper}>
                      <Form.Label
                        className={styles.toggleFormLabel}
                        htmlFor="personalization-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleLeft}</p>
                      </Form.Label>
                      <Form.Check
                        name="personalization"
                        type="switch"
                        id="personalization-toggle"
                        checked={personalization}
                      />
                      <Form.Label
                        className={styles.toggleFormLabel}
                        htmlFor="personalization-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleRight}</p>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <p>{personalizationSubTitle}</p>
                  <p>{personalizationCopy}</p>
                </Col>
              </Row>
            </div>
            <div className={`d-none ${styles.cookieCard}`}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <strong>{analyticsHeader}</strong>
                    <div className={styles.checkWrapper}>
                      <Form.Label
                        className={styles.toggleFormLabel}
                        htmlFor="analytics-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleLeft}</p>
                      </Form.Label>
                      <Form.Check
                        name="analytics"
                        type="switch"
                        id="analytics-toggle"
                        checked={analytics}
                      />
                      <Form.Label
                        className={styles.toggleFormLabel}
                        htmlFor="analytics-toggle"
                      >
                        <p className={styles.toggleLabel}>{toggleRight}</p>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <p>{analyticsSubTitle}</p>
                  <p>{analyticsCopy}</p>
                </Col>
              </Row>
            </div>
          </Form>
        </Row>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulCookiesPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      copy {
        copy
      }
      toggleLeft
      toggleRight
      personalizationHeader
      analyticsHeader
      personalizationSubTitle
      analyticsSubTitle
      personalizationCopy {
        personalizationCopy
      }
      analyticsCopy {
        analyticsCopy
      }
    }
  }
`;
